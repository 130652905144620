<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <div class="d-flex" style="align-items: center;">
              <i class="fa fa-solid fa fa-bullhorn " aria-hidden="true" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
              <h4 class="card-title ml-2">{{cvCardTitle}}</h4>
            </div>
          </template>
          <template v-slot:body>
            <form action="#" v-if="vmcustomFormBuildFormData && Object.keys(vmcustomFormBuildFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationcfb_id">
                    {{cvCfbIdLabel}}</label
                  >
                  <input
                    v-model="vmcustomFormBuildFormData.cfb_id"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <!-- Data Schema(only for JSON) -->
                  <div class="col-md-12 mb-3">
                    <label for="validationdata_schema">
                      {{cvFormConfigLabel}}</label
                    >
                    <textarea placeholder='{ "name": "index" }' v-model="vmcustomFormBuildFormData.form_config" type="json" @input="formatJSON" class="form-control textarea" id="json">
                    </textarea>
                    <span class="primary-color" v-if="invalidJSONMsg">{{invalidJSONMsg}}</span>
                  </div><!-- Data Schema(only for JSON) -->
                  <div class="col-md-12 mb-3">
                    <label for="validationform_table_name">
                      {{cvFormTableNameLabel}}</label
                    >
                    <input
                      v-model="vmcustomFormBuildFormData.form_table_name"
                      type="text"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationform_subs_id">
                      {{cvFormSubsIdLabel}}</label
                    >
                    <input
                      v-model="vmcustomFormBuildFormData.form_subs_id"
                      type="text"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationform_title">
                      {{cvFormTitleLabel}}</label
                    >
                    <input
                      v-model="vmcustomFormBuildFormData.form_title"
                      type="text"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationform_banner">
                      {{cvFormBannerLabel}}</label
                    >
                    <input
                      v-model="vmcustomFormBuildFormData.form_banner"
                      type="text"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationdata_schema">
                      {{cvFormDescLabel}}</label
                    >
                    <textarea placeholder='{ "name": "index" }' v-model="vmcustomFormBuildFormData.form_desc" type="json" @input="formatJSON" class="form-control textarea" id="json">
                    </textarea>
                    <span class="primary-color" v-if="invalidJSONMsg">{{invalidJSONMsg}}</span>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationform_email_field_variable">
                      {{cvFormEmailFieldVariableLabel}}</label
                    >
                    <input
                      v-model="vmcustomFormBuildFormData.form_email_field_variable"
                      type="text"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationform_name_field_variable">
                      {{cvFormNameFieldVariableLabel}}</label
                    >
                    <input
                      v-model="vmcustomFormBuildFormData.form_name_field_variable"
                      type="text"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationform_mobile_field_variable">
                      {{cvFormMobileFieldVariableLabel}}</label
                    >
                    <input
                      v-model="vmcustomFormBuildFormData.form_mobile_field_variable"
                      type="text"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationform_target_role">
                      {{cvFormTargetRoleLabel}}</label
                    >
                    <input
                      v-model="vmcustomFormBuildFormData.form_target_role"
                      type="text"
                      class="form-control"
                      required
                    />
                  </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="customFormBuildEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { customFormBuilds } from "../../../FackApi/api/customFormBuild"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"

export default {
  name: "customFormBuildEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propcustomFormBuildObj: {
      type: Object,
      default: function () {
        return {
          "cfb_id": "",
          "form_config": "",
          "form_table_name": "",
          "form_subs_id": "",
          "form_title": "",
          "form_banner": "",
          "form_desc": "",
          "form_email_field_variable": "",
          "form_name_field_variable": "",
          "form_mobile_field_variable": "",
          "form_target_role": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit customFormBuild",
      cvSubmitBtn: "Edit",
      cvCfbIdLabel: "cfb id",
      cvFormConfigLabel: "form config",
      cvFormTableNameLabel: "form table name",
      cvFormSubsIdLabel: "form subs id",
      cvFormTitleLabel: "form title",
      cvFormBannerLabel: "form banner",
      cvFormDescLabel: "form description",
      cvFormEmailFieldVariableLabel: "form email field variable",
      cvFormNameFieldVariableLabel: "form name field variable",
      cvFormMobileFieldVariableLabel: "form mobile field variable",
      cvFormTargetRoleLabel: "form target role",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "customFormBuild Updated",
      vmcustomFormBuildFormData: {},
      invalidJSONMsg: null
    }
  },
  mounted () {
    socialvue.index()
    this.customFormBuildView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmcustomFormBuildFormData) {
          if (!this.vmcustomFormBuildFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * customFormBuildView
     */
    async customFormBuildView () {
      try {
        if (this.propOpenedInModal) {
          this.vmcustomFormBuildFormData = this.propcustomFormBuildObj
        }
        else {
          let customFormBuildId = this.$route.params.cfb_id
          let customFormBuildViewResp = await customFormBuilds.customFormBuildView(this, customFormBuildId)

          if (customFormBuildViewResp && customFormBuildViewResp.resp_status) {
            this.vmcustomFormBuildFormData = customFormBuildViewResp.resp_data.data
            if (this.vmcustomFormBuildFormData.form_config) {
              this.formatJSON(null, this.vmcustomFormBuildFormData.form_config)
            }
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at customFormBuildView() and Exception:", err.message)
      }
    },
    /**
     * customFormBuildEdit
     */
    async customFormBuildEdit () {
      try {
        if (this.invalidJSONMsg) {
          this.toastMsg = "Invalid Json in form config field"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
        this.cvLoadingStatus = true
        let customFormBuildAddResp = await customFormBuilds.customFormBuildEdit(this, this.vmcustomFormBuildFormData)
        await ApiResponse.responseMessageDisplay(this, customFormBuildAddResp)

        if (customFormBuildAddResp && !customFormBuildAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitClosecustomFormBuildEditModal", this.vmcustomFormBuildFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at customFormBuildEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * formatJSON
     */
    formatJSON (event, unformattedJSON = null) {
      try {
        if (!unformattedJSON) {
          unformattedJSON = document.getElementById("json").value
        }
        let obj = JSON.parse(unformattedJSON)
        if (obj) {
          this.invalidJSONMsg = false
        }
        let formattedJSON = JSON.stringify(obj, undefined, 4)
        this.vmcustomFormBuildFormData.form_config = formattedJSON
      }
      catch (err) {
        this.invalidJSONMsg = "Invalid JSON"
      }
    }
  }
}
</script>
<style scoped>
.textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
